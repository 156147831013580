import React, { useEffect, useRef } from "react";
import Matter from "matter-js";

const PhysicsSquares = ({ containerRef }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const { clientWidth: width, clientHeight: height } = container;
    const engine = Matter.Engine.create();
    
    // Create a canvas manually
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    canvas.style.position = "absolute";
    canvas.style.top = "0";
    canvas.style.left = "0";
    canvas.style.zIndex = "1";
    canvas.style.pointerEvents = "none";

    container.appendChild(canvas);
    canvasRef.current = canvas;

    const render = Matter.Render.create({
      canvas: canvas,
      engine: engine,
      options: {
        width,
        height,
        wireframes: false,
        background: "transparent",
      },
    });

    // Add hidden ground for physics
    const ground = Matter.Bodies.rectangle(width / 2, height, width, 20, {
      isStatic: true,
      render: { fillStyle: "transparent" },
    });

    const addSquare = () => {
      const square = Matter.Bodies.rectangle(
        Math.random() * width,
        50,
        20,
        20,
        {
          restitution: 0.6, // Increased bounce
          render: { 
            fillStyle: (() => {
             const colors = ["red", "green", "blue", "white"];
             return colors[Math.floor(Math.random() * colors.length)];
            })(),
          }
        }
      );
      Matter.World.add(engine.world, square);
    };

    Matter.World.add(engine.world, [ground]);
    Matter.Runner.run(Matter.Runner.create(), engine);
    Matter.Render.run(render);

    const interval = setInterval(addSquare, 500);

    return () => {
      Matter.Render.stop(render);
      Matter.World.clear(engine.world);
      Matter.Engine.clear(engine);
      clearInterval(interval);
      container.removeChild(canvas);
    };
  }, [containerRef]);

  return null;
};

export default PhysicsSquares;
