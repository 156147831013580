import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import ContactForm from './contact';
import PhysicsSquares from './PhysicsSquares';
import ProfilePic from './assets/images/profile.png'; // Ensure correct import

const shapeVerbs = [
  'shape', 'form', 'mold', 'craft', 'sculpt', 'forge', 
  'create', 'engineer', 'Creative', 'Technologist', 'remodel', 'develop', 'harmonize',
  'construct', 'design', 'transform', 'frame', 'fashion', 
  'carve', 'assemble', 'configure', 'grow', 'structure', 'refine', 
  'alter', 'direct', 'steer', 'guide', 'reduce', 'compose', 'deconstruct',
  'arrange', 're-define', 'Audio', 'Video'
];

function getRandomIndex(exclude) {
  let randomIndex;
  do {
    randomIndex = Math.floor(Math.random() * shapeVerbs.length);
  } while (randomIndex === exclude);
  return randomIndex;
}

function App() {
  const physicsContainerRef = useRef(null);
  const [rollingWord, setRollingWord] = useState('');
  const textContainerRef = useRef(null);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setRollingWord(shapeVerbs[Math.floor(Math.random() * shapeVerbs.length)]);
    }, 4000);
    return () => clearInterval(intervalID);
  }, []);

  return (
    <div className="content">
      {/* Profile Image */}
      <div className="profile-section">
        <img src={ProfilePic} alt="Profile" className="profile-img" />
      </div>

      <header className="header">
        <ContactForm rollingWord={rollingWord} />
      </header>

      <main className="main">
        <div className="physics-container" ref={physicsContainerRef}>
         <PhysicsSquares containerRef={physicsContainerRef}/>
          <div className="overlay-text" ref={textContainerRef}>
            <p className="static-text">
              
            WEBSITE UNDER CONSTRUCTION.
            </p>
          </div>
        </div>
      </main>

      <footer className="footer">
        <p>© 2025 Harish Jariwala. Website concept built by Harish.</p>
      </footer>
</div>

  );
}

export default App;
