import './App.css';
import React, { useState, useEffect } from "react";

const shapeVerbs = [
  'shape', 'form', 'mold', 'craft', 'sculpt', 'forge', 
  'create', 'engineer', 'remodel', 'develop', 'harmonize',
  'construct', 'design', 'transform', 'frame', 'fashion', 
  'carve', 'assemble', 'configure', 'grow', 'structure', 'refine', 
  'alter', 'direct', 'steer', 'guide', 'reduce', 'compose', 'deconstruct',
  'arrange', 're-define', 'Audio', 'Video'
];

function getRandomIndex(exclude) {
  let randomIndex;
  do {
    randomIndex = Math.floor(Math.random() * shapeVerbs.length);
  } while (randomIndex === exclude);
  return randomIndex;
}

const ContactForm = () => {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [status, setStatus] = useState("");
  const [submitted, setSubmitted] = useState(false); // Track submission status
  const [rollingWord, setRollingWord] = useState('talk');
  const [toggle, setToggle] = useState(true); // Toggle between rollingWord and "talk"

  useEffect(() => {
    const intervalID = setInterval(() => {
      setRollingWord(prev => toggle ? shapeVerbs[getRandomIndex(shapeVerbs.indexOf(prev))] : 'talk');
      setToggle(prevToggle => !prevToggle);
    }, 4000);

    return () => clearInterval(intervalID);
  }, [toggle]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    setSubmitted(false);

    try {
      console.log("Sending request to:", process.env.REACT_APP_API_URL);
      console.log("Request body:", JSON.stringify(formData));

      const response = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      console.log("Raw Response:", response);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Parsed Response:", result);

      setStatus(result.message || "Message sent successfully!");
      setSubmitted(true);
      setFormData({ name: "", email: "", message: "" }); // Reset form fields

      // Hide success message after 5 seconds
      setTimeout(() => {
        setStatus("");
        setSubmitted(false);
      }, 5000);

    } catch (error) {
      console.error("Fetch Error:", error);
      setStatus(`Error: ${error.message}`);
    }
  };

  return (
    <div className="ContactForm">
      {submitted ? (
        <div className="thank-you-message">
          <h2>Thank you! Your message has been sent.</h2>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
          <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
          <textarea name="message" placeholder={`Let's ${rollingWord}...`} value={formData.message} onChange={handleChange} required></textarea>
          <button type="submit">Send</button>
        </form>
      )}
      {status && <p className="status-message">{status}</p>}
    </div>
  );
};

export default ContactForm;
